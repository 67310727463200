import axios from "@/plugins/axios.js";

// 订单管理列表
export function orderListApi(params) {
  let res1 = axios.get("/order/page", { params });
  return res1;
}

// 订单详情
export function orderDetailInfoApi(orderNo) {
  let res1 = axios.get("/order/details/" + orderNo);
  return res1;
}
// 退单管理列表
export function refundListApi(params) {
  let res1 = axios.get("/school/refund/page", {
    params,
  });
  return res1;
}
// 退单详情
export function refundDetailInfoApi(refundId) {
  let res1 = axios.get("/school/refund/detail/" + refundId);
  return res1;
}
// 学校端退款审核
export function refundAudit(data) {
  return axios.post("/school/refund/verify", data);
}

// 结算管理-列表
export function settleListApi(data) {
  return axios.get("/school/settlement/page", { params: data });
}

// 结算管理-查看订单
export function settleDetailApi(data) {
  return axios.get("/school/settlement/orderDetail", { params: data });
}

// 结算管理-结算凭证查询
export function settleVoucherDetailApi(data) {
  return axios.get("/school/settlement/voucher/" + data.settlementItemId, {
    params: data,
  });
}

// 结算管理-提交/修改结算凭证
export function settlementSubmitVoucherApi(data) {
  return axios.get("/school/settlement/submit/voucher", {
    params: data,
  });
}

// 结算管理-结算单明细提交结算凭证校验
export function checkSubmitVoucherApi(data) {
  return axios.post(
    `/school/settlement/checkSubmitVoucher/${data.settlementItemId}`,
    {
      params: data,
    }
  );
}
