<template>
  <div v-loading="allLoading" class="order-manage">
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      noEmptyText="el.common.noData"
    >
      <template slot="otherHandle" slot-scope="scope">
        <el-button
          v-btn="'btn-refund-detail'"
          type="text"
          size="small"
          @click="handleGoDetail(scope.row)"
          >{{ $t("el.courseDetails.details") }}</el-button
        >
      </template>
      <template slot="formBtn">
        <el-button
          v-btn="'btn-refund-list-export'"
          size="small"
          class="teachManage-tool-btnicon"
          type="primary"
          @click="handleExport()"
          >{{ $t("el.common.export") }}</el-button
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { orderListApi } from "@/api/manage/order";
import { handparams } from "@/utils";

export default {
  computed: {
    ...mapGetters({}),
    ...mapState({
      // 当前学校租户下的年级
      gradeOptions(state) {
        return state.dict.TENANT_GRADE.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
      tenantId: (state) => state.schoolCode,
    }),

    // 筛选项字段
    formItems() {
      return [
        // 订单编号
        {
          itemType: "input",
          prop: "orderNo",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.orderNo"),
          clearable: true,
        },
        // 创建时间
        {
          itemType: "daterange",
          prop: "createTime",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.createTime"),
          clearable: true,
        },
        // 课程名称
        {
          itemType: "input",
          prop: "courseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.CourseName"),
          clearable: true,
        }, // 收款商户
        {
          itemType: "input",
          prop: "merchantName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.shoukuanshanghu"),
          clearable: true,
        },
        // 报名学生
        {
          itemType: "input",
          prop: "studentName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.registeredStudents"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "studentGrade",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.grade"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "studentClass",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.order.class"),
          clearable: true,
        },
        // {
        //   itemType: "select",
        //   prop: "gradeId",
        //   size: "small",
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.order.grade"),
        //   clearable: true,
        //   options: this.gradeOptions,
        // },
        // // 班级
        // {
        //   itemType: "select",
        //   prop: "classId",
        //   size: "small",
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.order.class"),
        //   clearable: true,
        //   options: this.classOptions,
        // },
        // 用户手机号
        {
          itemType: "input",
          prop: "accountPhone",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.userPhoneNumber"),
          clearable: true,
          itemStyle: "width:150px;",
        }, // 订单状态
        {
          itemType: "select",
          prop: "status",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.orderStatus"),
          clearable: true,
          options: this.$store.state.dict.ORDER_STATUS.map((el) => {
            return {
              label: el.dictionaryText,
              value: el.dictionaryKey,
            };
          }).filter((i) => i.value < 4),
        },
      ];
    },
    // 表格项
    columns() {
      return [
        {
          label: this.$t("el.order.orderNo"),
          prop: "orderNo",
        },
        {
          label: this.$t("el.order.createTime"),
          prop: "creatTimeStr",
        },
        {
          label: this.$t("el.order.courseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.order.shoukuanshanghu"),
          prop: "merchantName",
        },
        {
          label: this.$t("el.order.registeredStudents"),
          prop: "studentName",
        },
        {
          label: this.$t("el.order.grade"),
          prop: "studentGrade",
        },
        {
          label: this.$t("el.order.class"),
          prop: "studentClass",
        },
        {
          label: this.$t("el.order.userPhoneNumber"),
          prop: "accountPhone",
        },
        {
          label: this.$t("el.order.dingdanjine") + this.$t("el.order.yuan"),
          prop: "totalPrice",
        },
        {
          label: this.$t("el.order.shifujine") + this.$t("el.order.yuan"),
          prop: "actualAmount",
          formatter: (row, column, value) => {
            return value === null || value === undefined ? "-" : value;
          },
        },
        {
          label: this.$t("el.order.orderStatus"),
          prop: "status",
          formatter: (row, column, value) => {
            return this.$store.state.dict.ORDER_STATUS.map(
              (el) => el.dictionaryText
            )[value - 1];
          },
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
        },
      ];
    },
  },
  data() {
    return {
      allLoading: false,
      classOptions: [],
    };
  },
  created() {
    // this.handleGetYear();
    this.handleGetSubject();
  },
  methods: {
    // 获取列表数据
    fatchMethod(params) {
      this.doTimeParams(params);
      return orderListApi(handparams(params));
    },
    doTimeParams(params) {
      delete params.createTime;
      if (params.startTime) {
        params.startTime = +new Date(params.startTime + " 00:00:00");
      }
      if (params.endTime) {
        params.endTime = +new Date(params.endTime + " 23:59:59");
      }
    },
    // 获取列表内全部科目
    handleGetSubject() {},

    // 查看详情
    handleGoDetail(row) {
      const query = { orderNo: row.orderNo };
      this.$router.push({
        name: "orderDetail",
        query,
      });
    },
    /**
     * 导出
     */
    async handleExport() {
      let queryParams = this.$refs.xmTable.getQueryParams(true);
      this.doTimeParams(queryParams);

      this.allLoading = true;
      if (this.$refs.xmTable.getNowDataList().length > 0) {
        try {
          await this.$utils.exportDownCommon(
            "get",
            handparams(queryParams),
            "/order/export",
            `学校订单_${new Date().getTime()}.xlsx`
          );
        } catch (error) {
          //
        }
      } else {
        this.$message.warning(this.$t("el.common.noData"));
      }

      this.allLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.order-manage {
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
  .syncedTip {
    text-align: right;
    font-size: 14px;
    color: #807f7f;
    margin-bottom: 12px;
  }
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
}
</style>
