import { render, staticRenderFns } from "./orderList.vue?vue&type=template&id=c00d0238&scoped=true"
import script from "./orderList.vue?vue&type=script&lang=js"
export * from "./orderList.vue?vue&type=script&lang=js"
import style0 from "./orderList.vue?vue&type=style&index=0&id=c00d0238&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_prettier@2.8.8_vue-temp_pgqv4bjfw4n43uthtcnbh5tcie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c00d0238",
  null
  
)

export default component.exports